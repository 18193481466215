:root {
    --greenia-color: #00a651;
    --danger-color: #dc3545;
}

html,body,#root {
    height: 100%;
}

.alert-document {
    margin: 10px;
    width: calc(50% - 20px);
}

.custom-row {
    display: flex;
    padding: 10px 20px;
    gap: 20px;
}

@media (max-width: 1300px) {
    .custom-row {
        flex-wrap: wrap;
    }
}

.custom-card {
    background-color: white;
    box-shadow: 0px 0px 10px rgb(210, 210, 210);
    flex: 1;
    border-radius: 10px;
}

.custom-card-header {
    padding: 10px 25px;
    padding-top: 15px;
    background-color: rgb(249, 249, 249);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-wrap: nowrap;
}

.custom-card-body {
    padding: 10px 25px;
    padding-bottom: 30px;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-wrap: nowrap;
    max-height: 350px;
    overflow-y: auto;
}

.custom-card-body--body-only {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.custom-card-section {
    flex: 1;
    padding: 5px;
}

.x-modal {
    padding: 10px;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.x-modal-cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0.5;
    cursor: pointer;
    z-index: 1001;
    background: #525252;
}
.x-modal-content{
    background-color: #ffffff;
    padding: 10px;
    position: relative;
    z-index: 1001;
    border-radius: 10px;
    max-width: 320px;
    text-align: center;
}
.x-modal-body{
    padding: 10px;
}
.x-modal-close{
    position: absolute;
    right: 0;
    width: 36px;
    height: 36px;
    top: -12px;
    background: #fff;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.x-modal-close svg{
    width: 100%;
}

.general-modal-content {
    width: 100%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    background-color: #ffffff;
    padding: 10px;
    position: relative;
    z-index: 1001;
    border-radius: 10px;
}

.general-modal-close{
    position: absolute;
    right: 0;
    width: 36px;
    height: 36px;
    top: 0px;
    background: #fff;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.general-modal-close svg{
    width: 100%;
}